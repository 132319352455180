import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { ReactComponent as HaltingHandIcon } from "assets/vectors/halting_hand.svg";
import { ReactComponent as PendingClockIcon } from "assets/vectors/pending_clock.svg";
import React from "react";

export const BOOKING_STATUS_ID = {
  active: 0,
  pending: 1,
  halted: 2,
  cancelled: 3,
  completed: 4,
  failed: 5,
};

export const AUTO_DEBIT_STATUS_TEXT = {
  Active:
    "A subscription is considered active if 3+ days are left for it to renew.",
  Due: "A subscription is considered due if 3 or less days are left for it to renew.",
  Overdue:
    "A subscription is considered overdue if less than 24 hours have passed since the last billing cycle and it is not renewed yet.",
  Failed: "A subscription is considered failed if it is not renewed on time.",
  Cancelled:
    "A subscription is considered cancelled when the customer cancels it. They'll have access to the offering if cancellation occurs before the next billing cycle.",
  Expired: "A subscription is considered expired if its duration is complete",
};

export const BOOKING_STATUS_MAP = {
  [BOOKING_STATUS_ID.active]: {
    title: "Active",
    variant: "fine_pine",
    icon: null,
    className: "active_status",
  },
  [BOOKING_STATUS_ID.pending]: {
    title: "Pending",
    variant: "butterscotch_yellow",
    icon: <PendingClockIcon />,
  },
  [BOOKING_STATUS_ID.halted]: {
    title: "Halted",
    variant: "danger",
    icon: <HaltingHandIcon />,
  },
  [BOOKING_STATUS_ID.cancelled]: {
    title: "Cancelled",
    variant: "danger",
    icon: <CancelRoundedIcon className="mui_icon" />,
  },
  [BOOKING_STATUS_ID.completed]: {
    title: "Completed",
    variant: "fine_pine",
    icon: <CheckCircleRoundedIcon className="mui_icon" />,
  },
};

export const INACTIVE_BOOKING_STATUS = {
  label: "Inactive",
  className: "inactive_status",
};

// @muskan move this to features/bookings later (along with crmp2 revamp)
export const FLEXIBLE_ONE_TIME_FILTER_OPTIONS = [
  // @DEV do not map value for active with BOOKING_STATUS_ID.active as that is 0 but filter value for active should be 1
  { label: BOOKING_STATUS_MAP[BOOKING_STATUS_ID.active]?.title, value: 1 },
  { label: INACTIVE_BOOKING_STATUS.label, value: BOOKING_STATUS_ID.completed },
];
