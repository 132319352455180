export const TRAFFIC_SOURCE_VALUES = {
  OTHERS: 1,
  ORGANIC: 2,
  DIRECT: 3,
  INSTAGRAM: 4,
  WHATSAPP: 5, // TODO:QUASHID - Commenting out source whatsapp for now as backend isn't checking analytics for source whatsapp
  YOUTUBE: 6,
  FACEBOOK: 7,
  LINKEDIN: 8,
  TWITTER: 9,
};

export const TRAFFIC_SOURCE_LABELS = {
  [TRAFFIC_SOURCE_VALUES.OTHERS]: "Others",
  [TRAFFIC_SOURCE_VALUES.ORGANIC]: "Organic",
  [TRAFFIC_SOURCE_VALUES.DIRECT]: "Direct",
  [TRAFFIC_SOURCE_VALUES.INSTAGRAM]: "Instagram",
  [TRAFFIC_SOURCE_VALUES.WHATSAPP]: "Whatsapp",
  [TRAFFIC_SOURCE_VALUES.YOUTUBE]: "Youtube",
  [TRAFFIC_SOURCE_VALUES.FACEBOOK]: "Facebook",
  [TRAFFIC_SOURCE_VALUES.LINKEDIN]: "Linkedin",
  [TRAFFIC_SOURCE_VALUES.TWITTER]: "Twitter",
};

export const TRAFFIC_SOURCE_DROPDOWN_OPTIONS = Object.values(
  TRAFFIC_SOURCE_VALUES
).map((trafficSource) => ({
  id: trafficSource,
  value: trafficSource,
  label: TRAFFIC_SOURCE_LABELS[trafficSource],
}));

export const analyticsConstants = {
  lead_source: TRAFFIC_SOURCE_DROPDOWN_OPTIONS,
};
